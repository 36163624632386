<template>
  <div>
    <div class="tab-list">
      <div
        v-for="(item, i) in tabList"
        :key="i"
        class="tab-item"
        :class="{ active: i === activeColor }"
        @click="changeClick(item, i)"
      >
        <svg-icon :icon-class="[i === activeColor ? item.activeIcon : item.icon]" />
        <div>{{ item.name }}</div>
      </div>
    </div>
    <div v-loading="isLoading">
      <el-table
        v-if="activeColor===1"
        ref="aiTable"
        :data="tableData"
        :row-style="tableCellStyle"
        style="width: 100%"
        @filter-change="filterChange"
      >
        <el-table-column
          prop="accountLogId"
          label="交易流水号"
        />
        <el-table-column
          prop="podName"
          label="pod名称"
        />
        <el-table-column
          prop="money"
          label="消费金额"
        >
          <template #default="scope">
            ￥{{ getMoney(scope.row.money) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="consumptionState"
          column-key="consumptionState"
          label="消费状态"
          :filters="[
            { text: '支付失败', value: 0 },
            { text: '支付成功', value: 1 },
            { text: '待支付', value: 2 }
          ]"
          :filtered-value="[0, 1, 2]"
        >
          <template #default="scope">
            <div style="display: flex;">
              <div :class="scope.row.consumptionState === 0 ? 'anormaly-text' : (scope.row.consumptionState === 1 ? 'end-text' : 'normal-text')">{{ scope.row.consumptionState === 0 ? '支付失败' : (scope.row.consumptionState === 1 ? '支付成功' : '待支付') }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="消费时间"
        />
        <el-table-column
          prop="realConsumeProjectName"
          label="项目组"
        />
        <el-table-column
          prop="accountName"
          label="消费人"
        />
        <el-table-column
          prop="realConsumeAccountName"
          label="扣费账户"
        />
      </el-table>
      <!-- 作业消费 -->
      <el-table
        v-else-if="activeColor===0"
        ref="jobTable"
        :data="tableData"
        :row-style="tableCellStyle"
        style="width: 100%"
        @filter-change="filterChange"
      >
        <el-table-column
          prop="accountLogId"
          label="交易流水号"
        />
        <el-table-column
          prop="jobId"
          label="作业ID"
        />
        <el-table-column
          prop="jobName"
          label="作业名称"
        />
        <el-table-column
          prop="cpusReq"
          label="作业规模"
        />
        <el-table-column
          prop="jobState"
          label="作业状态"
          column-key="jobState"
          :filters="[
            { text: '运行中', value: 0 },
            { text: '已完成', value: 2 },
            { text: '异常', value: 3 }
          ]"
          :filtered-value="[0, 2, 3]"
        >
          <template #default="scope">
            <div
              v-if="scope.row.jobState === 'JOB_RUNNING' || scope.row.jobState === 0"
              class="state_circles"
              style="color: #165DFF"
            >
              <div>运行中</div>
            </div>
            <div
              v-if="scope.row.jobState === 'JOB_COMPLETE' || scope.row.jobState === 2"
              class="state_circles"
              style="color: #00A870"
            >
              <div>已完成</div>
            </div>
            <div
              v-if="scope.row.jobState === 1"
              class="state_circles"
              style="color: #FD9851"
            >
              <div>排队中</div>
            </div>
            <div
              v-if="scope.row.jobState === 3"
              class="state_circles"
              style="color: #F02E3E"
            >
              <div>异常</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="money"
          label="消费金额"
        >
          <template #default="scope">
            ￥{{ getMoney(scope.row.money) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="consumptionState"
          column-key="consumptionState"
          label="状态"
          :filters="[
            { text: '支付失败', value: 0 },
            { text: '支付成功', value: 1 },
            { text: '待支付', value: 2 }
          ]"
          :filtered-value="[0, 1, 2]"
        >
          <template #default="scope">
            <div style="display: flex;">
              <div :class="scope.row.consumptionState === 0 ? 'anormaly-text' : (scope.row.consumptionState === 1 ? 'end-text' : 'normal-text')">{{ scope.row.consumptionState === 0 ? '支付失败' : (scope.row.consumptionState === 1 ? '支付成功' : '待支付') }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="消费开始时间"
        />
        <el-table-column
          prop="stateTime"
          label="最后消费时间"
        >
          <template #default="scope">
            {{ filterTime(scope.row.stateTime) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="remark"
          label="消费描述"
        />
      </el-table>
      <!-- 磁盘配额 -->
      <el-table
        v-else
        ref="quotaTable"
        :data="tableData"
        :row-style="tableCellStyle"
        style="width: 100%"
        @filter-change="filterChange"
      >
        <el-table-column
          prop="accountLogId"
          label="交易流水号"
        />
        <el-table-column
          prop="money"
          label="交易金额"
        >
          <template #default="scope">
            ￥{{ getMoney(scope.row.money) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="consumptionState"
          column-key="consumptionState"
          label="交易状态"
          :filters="[
            { text: '支付失败', value: 0 },
            { text: '支付成功', value: 1 },
            { text: '待支付', value: 2 }
          ]"
          :filtered-value="[0, 1, 2]"
        >
          <template #default="scope">
            <div style="display: flex;">
              <div :class="scope.row.consumptionState === 0 ? 'anormaly-text' : (scope.row.consumptionState === 1 ? 'end-text' : 'normal-text')">{{ scope.row.consumptionState === 0 ? '支付失败' : (scope.row.consumptionState === 1 ? '支付成功' : '待支付') }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="quotaSize"
          label="扩容"
        />
        <el-table-column
          prop="endTime"
          label="有效期"
        >
          <template #default="scope">
            <div v-if="scope.row.endTime===null">
              永久有效
            </div>
            <div v-else>
              {{ scope.row.endTime }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="quotaState"
          label="状态"
          column-key="quotaState"
          :filters="[
            { text: '有效', value: 1 },
            { text: '已失效', value: 2 }
          ]"
          :filtered-value="[1, 2]"
        >
          <template #default="scope">
            <div style="display: flex;">
              <div :class="scope.row.quotaState === 2 ? 'anormaly-text' : 'normal-text'">{{ scope.row.quotaState === 2 ? '已失效' : '有效' }}</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <div class="total">合计：{{ totalPage }}</div>
        <el-pagination
          v-model:currentPage="pageNum"
          :page-size="pageSize"
          :page-count="pagenumber"
          :page-sizes="pageSizes"
          layout="sizes"
          :total="totalPage"
          @size-change="handleSizeChange"
        />
        <div class="jumper">
          <div>{{ pageNum }}/{{ pagenumber }}</div>
          <div class="jumper-line" />
          <el-pagination
            v-model:currentPage="pageNum"
            :page-size="pageSize"
            :page-count="pagenumber"
            :page-sizes="pageSizes"
            background
            layout="jumper"
            :total="totalPage"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
        <el-pagination
          v-model:currentPage="pageNum"
          :page-size="pageSize"
          :page-count="pagenumber"
          :page-sizes="pageSizes"
          background
          layout="prev, next"
          :total="totalPage"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getConsumptionNoteByMonth } from '@/api/ehpc'
export default {
  props: ['month'],
  data() {
    return {
      isLoading: false,
      activeColor: 0,
      tabList: [{
        name: '作业消费',
        activeIcon: 'job-on',
        icon: 'job'
      // },
      // {
      //   name: 'AI消费',
      //   activeIcon: 'ai-on',
      //   icon: 'ai'
      // },
      // {
      //   name: '磁盘配额订单',
      //   activeIcon: 'quota-on',
      //   icon: 'quota'
      }],
      startTime: null,
      endTime: null,
      consumptionState: [],
      jobState: [],
      quotaState: [],
      // 搜索
      submitDate: '',
      tableData: [],
      loading: false,
      pageSize: 10,
      row: '',
      pageNum: 1,
      pagenumber: 0,
      totalPage: 0,
      pageSizes: [5, 10, 20, 30],
      search: '',
      isChangePageNum: false
    }
  },
  watch: {
    month: {
      handler(val, oldVal) {
        this.pageNum = 1
        this.isChangePageNum = false
        this.$nextTick(() => {
          this.getData()
        })
      },
      deep: true
    },
    pageNum: {
      handler(val, oldVal) {
        if (this.isChangePageNum) {
          this.pageNum = val
          this.getData()
        }
      },
      deep: true
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getMoney(money) {
      return (money / 1000).toFixed(3).replace(/\.?0+$/, '')
    },
    changeClick(item, i) {
      if (i !== this.activeColor) {
        this.activeColor = i
        this.tableData = []
        this.jobState = []
        this.quotaState = []
        this.consumptionState = []
        this.pageNum = 1
        this.isChangePageNum = false
        this.search = ''
        this.submitDate = ''
        this.$nextTick(() => {
          this.getData()
        })
      }
    },
    getData() {
      // this.$emit('viewLoadingChange', true)
      this.isLoading = true
      // 作业状态
      const data = {
        pagenum: this.pageNum,
        pagesize: this.pageSize,
        month: this.month,
        jobState: this.jobState,
        consumptionState: this.consumptionState,
        consumerType: this.activeColor === 0 ? 1 : this.activeColor === 1 ? 3 : 2
      }
      getConsumptionNoteByMonth(data).then(res => {
        if (res.meta.status === 200) {
          this.isLoading = false
          this.$emit('getConsumerTotal', res.data.moneyMonth)
          this.tableData = res.data.consumerMonth.content
          this.totalPage = res.data.consumerMonth.totalelements
          this.pagenumber = res.data.consumerMonth.totalpages
        } else {
          this.isLoading = false
          // this.$emit('viewLoadingChange', false)
        }
      }).catch(() => {
        this.isLoading = false
        // this.$emit('viewLoadingChange', false)
      })
    },
    filterTime(time) {
      var date = new Date(time)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      var h = date.getHours()
      h = h < 10 ? '0' + h : h
      var minute = date.getMinutes()
      minute = minute < 10 ? '0' + minute : minute
      var s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + s
    },
    tableCellStyle(row) {
      if (this.row === row.row) {
        return 'background-color:#f5f6fa;'
      } else {
        return 'background-color:#fff;'
      }
    },
    // 筛选改变
    filterChange(val) {
      if (val.consumptionState) {
        this.consumptionState = val.consumptionState.join(',')
      }
      if (val.jobState) {
        this.jobState = val.jobState.join(',')
      }
      if (val.quotaState) {
        this.quotaState = val.quotaState.join(',')
      }
      this.pageNum = 1
      this.isChangePageNum = false
      this.$nextTick(() => {
        this.getData()
      })
    },
    // 分页
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNum = 1
      this.isChangePageNum = false
      this.getData()
    },
    handleCurrentChange(val) {
      // this.pageNum = val
      this.isChangePageNum = true
      // this.getData()
    }
  }
}
</script>

<style lang="less" scoped>
.tab-list{
    padding-left: 16px;
    padding-bottom: 16px;
    display: flex;
    .tab-item{
      width: 84px;
      color: #303133;
      font-size: 14px;
      text-align: center;
      margin-right: 32px;
      .svg-icon{
        width: 36px;
        height: 36px;
      }
    }
    .active{
      color: #165dff;
    }
  }
</style>
