<template>
  <div class="user-account">
    <div class="content">
      <div class="account-left">
        <div v-for="(item,i) in accountTagList" :key="i" :class="{ accountActive: i === accountActive }" class="tag-box" @click="tagHandle(item, i)">{{ item }}</div>
      </div>
      <div v-loading="viewLoading" class="account-right">
        <account-view v-if="accountActive===0" @viewLoadingChange="viewLoadingChange" @queryDetailByLogId="queryDetailByLogId" />
        <order-detail v-if="accountActive===1" :id="detailKeyword" :type="detailConsumerType" @viewLoadingChange="viewLoadingChange" />
        <recharge-record v-if="accountActive===2" @viewLoadingChange="viewLoadingChange" />
        <invoice-control v-if="accountActive===3" @viewLoadingChange="viewLoadingChange" />
      </div>
    </div>
  </div>
</template>

<script>
import accountView from '../components/AccountView.vue'
import orderDetail from '../components/OrderDetail.vue'
import rechargeRecord from '../components/RechargeRecord.vue'
import invoiceControl from '../applications/invoiceControl.vue'
export default {
  components: { accountView, orderDetail, rechargeRecord, invoiceControl },
  data() {
    return {
      // 页面标题标签
      accountTagList: ['账单概览', '详单查询', '充值记录', '发票管理'],
      accountActive: 0,
      viewLoading: false,
      detailKeyword: '',
      detailConsumerType: null
    }
  },
  mounted() {
    sessionStorage.userAccountManual = 'view'
  },
  methods: {
    // 标题标签切换
    tagHandle(item, i) {
      if (item !== this.detailKeyword) {
        sessionStorage.detailKeyword = null
      }
      this.accountActive = i
      if (i === 0) {
        sessionStorage.userAccountManual = 'view'
      } else if (i === 1) {
        sessionStorage.userAccountManual = 'detail'
      } else if (i === 2) {
        sessionStorage.userAccountManual = 'record'
      } else if (i === 3) {
        sessionStorage.userAccountManual = 'invoice'
      }
    },
    viewLoadingChange(data) {
      this.viewLoading = data
    },
    queryDetailByLogId(id, type) {
      this.detailKeyword = id
      sessionStorage.detailKeyword = id
      sessionStorage.detailConsumerType = type
      this.detailConsumerType = type
      this.$nextTick(() => {
        this.tagHandle(id, 1)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.user-account{
  height: 100%;
  width: 100%;
  .content{
    display: flex;
    height: 100%;
    width: 100%;
  }
  .account-left{
    min-height: 100%;
    min-width: 146px;
    padding: 16px;
    border-right: 1px solid #d8d8d8;
    .tag-box{
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #303133;
    }
    .tag-box:hover{
      color: #165DFF;
    }
    .accountActive{
      background: #ECF2FE;
      color: #165DFF;
    }
  }
  .account-right{
    padding: 20px;
    width: calc(100% - 146px);
    min-height: 100%;
    // overflow: scroll;
    overflow-y: scroll;
    overflow-x: visible;
    ::v-deep .el-overlay{
      background-color: transparent;
    }
    ::v-deep .el-drawer.rtl{
      width: 1000px !important;
      overflow-x: scroll;
      background-color: #F5F7FA !important;
      .el-icon{
        font-size: 12px;
      }
      .consume-box{
        padding: 16px;
        background-color: #fff;
        margin: 0 16px;
      }
      .recharge-box{
        margin: 16px 16px 8px;
        background-color: #fff;
        padding: 16px;
        padding-right: 0;
        color: #000;
        font-size: 14px;
        .recharge-content{
          padding-right: 16px;
          .el-table{
            margin-top: 16px;
          }
        }
        .total-header{
          display: flex;
          align-items: center;
          padding-bottom: 16px;
          margin-bottom: 16px;
          border-bottom: 1px solid #E7E7E7;
          .el-date-editor.el-input, .el-date-editor.el-input__wrapper{
            margin-left: 8px;
            .el-icon{
              color: rgba(0, 0, 0, 0.4);
              font-size: 13px;
            }
          }
          .el-date-editor.el-input, .el-date-editor.el-input__wrapper{
            width: 186px;
          }
          .total{
            // padding-left: 120px;
            width: 33%;
            span{
              font-weight: bold;
              font-size: 22px;
              color: #165DFF;
            }
          }
        }
      }
      .el-drawer__header{
        border-color: #e7e7e7;
        font-weight: 500;
        background-color: #fff !important;
        .el-drawer__title{
          font-size: 18px;
        }
        .el-drawer__close-btn{
          display: block;
          .el-icon{
            font-size: 14px;
            color: #000;
          }
        }
      }
    }
  }
}
</style>
<style>
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 12px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3); */
  border-radius: 6px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.5); */
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #d8d8d8;
}
</style>
