<template>
  <div class="recharge-record">
    <div class="detail">
      <div class="detail-header">
        <el-input
          v-model="search"
          class="search"
          placeholder="搜索"
          prefix-icon="Search"
          clearable
          @input="searchHandle(search)"
        />
        <span style="submit-time">交易时间</span>
        <el-date-picker
          v-model="submitDate"
          type="datetimerange"
          start-placeholder="开始时间"
          end-placeholder="截止时间"
          @change="dateChange"
        />
      </div>
      <div>
        <el-table
          :data="tableData"
          :row-style="tableCellStyle"
          style="width: 100%"
          @filter-change="filterChange"
        >
          <el-table-column
            prop="accountLogId"
            label="交易流水号"
          />
          <el-table-column
            prop="comeInReal"
            label="支付金额"
          >
            <template #default="scope">
              ￥{{ getMoney(scope.row.comeInReal) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="channelType"
            label="充值渠道"
            column-key="channelType"
            :filters="[
              { text: '支付宝', value: 'alipay' },
              { text: '微信', value: 'wxpay' },
              { text: '系统充值', value: 'system' }
            ]"
            :filtered-value="['alipay', 'wxpay', 'system']"
          >
            <template #default="scope">
              <div>{{ scope.row.channelType === 'alipay' ? '支付宝' : (scope.row.channelType === 'wxpay' ? '微信' : '系统充值') }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="comeIn"
            label="到账金额"
          >
            <template #default="scope">
              ￥{{ getMoney(scope.row.comeIn) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="comeFree"
            label="赠送金额"
          >
            <template #default="scope">
              ￥{{ getMoney(scope.row.comeFree) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="交易时间"
          />
          <el-table-column
            prop="consumptionState"
            label="状态"
          >
            <template #default="scope">
              <div style="display: flex;">
                <div :class="scope.row.consumptionState === 0 ? 'anormaly-text' : (scope.row.consumptionState === 1 ? 'end-text' : 'normal-text')">{{ scope.row.consumptionState === 0 ? '支付失败' : (scope.row.consumptionState === 1 ? '支付成功' : '待支付') }}</div>
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="remark"
            label="描述"
          >
            <template #default="scope">
              <div v-if="scope.row.remark">{{ scope.row.remark }}</div>
              <div v-else>无</div>
            </template>
          </el-table-column> -->
        </el-table>
        <div class="pagination">
          <div class="total">合计：{{ totalPage }}</div>
          <el-pagination
            v-model:currentPage="pageNum"
            :page-size="pageSize"
            :page-count="pagenumber"
            :page-sizes="pageSizes"
            layout="sizes"
            :total="totalPage"
            @size-change="handleSizeChange"
          />
          <div class="jumper">
            <div>{{ pageNum }}/{{ pagenumber }}</div>
            <div class="jumper-line" />
            <el-pagination
              v-model:currentPage="pageNum"
              :page-size="pageSize"
              :page-count="pagenumber"
              :page-sizes="pageSizes"
              background
              layout="jumper"
              :total="totalPage"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
          <el-pagination
            v-model:currentPage="pageNum"
            :page-size="pageSize"
            :page-count="pagenumber"
            :page-sizes="pageSizes"
            background
            layout="prev, next"
            :total="totalPage"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRechargeNote } from '@/api/ehpc'
export default {
  data() {
    return {
      activeColor: 0,
      tabList: [{
        name: '作业消费',
        activeIcon: 'job-on',
        icon: 'job'
      // },
      // {
      //   name: 'AI消费',
      //   activeIcon: 'ai-on',
      //   icon: 'ai'
      // },
      // {
      //   name: '磁盘配额订单',
      //   activeIcon: 'quota-on',
      //   icon: 'quota'
      }],
      startTime: null,
      endTime: null,
      channelType: [],
      // 搜索
      submitDate: '',
      tableData: [],
      loading: false,
      pageSize: 10,
      row: '',
      pageNum: 1,
      pagenumber: 0,
      totalPage: 0,
      pageSizes: [5, 10, 20, 30],
      search: '',
      isChangePageNum: false
    }
  },
  watch: {
    pageNum: {
      handler(val, oldVal) {
        if (this.isChangePageNum) {
          this.pageNum = val
          this.getData()
        }
      },
      deep: true
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getMoney(money) {
      return (money / 1000).toFixed(3).replace(/\.?0+$/, '')
    },
    changeClick(item, i) {
      this.activeColor = i
      this.pageNum = 1
      this.isChangePageNum = false
      this.$nextTick(() => {
        this.getData()
      })
    },
    getData() {
      this.$emit('viewLoadingChange', true)
      // 作业状态
      const data = {
        pagenum: this.pageNum,
        pagesize: this.pageSize,
        // sortBy: this.sortBy,
        // orderBy: this.orderBy,
        keyWord: this.search,
        startTime: this.startTime,
        endTime: this.endTime,
        channelType: this.channelType
      }
      getRechargeNote(data).then(res => {
        if (res.meta.status === 200) {
          this.$emit('viewLoadingChange', false)
          this.tableData = res.data.content
          this.totalPage = res.data.totalelements
          this.pagenumber = res.data.totalpages
        } else {
          this.$emit('viewLoadingChange', false)
        }
      }).catch(() => {
        this.$emit('viewLoadingChange', false)
      })
    },
    // 搜索
    searchHandle() {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.pageNum = 1
        this.isChangePageNum = false
        this.$nextTick(() => {
          this.getData()
        })
      }, 500)
    },
    // 提交时间更改
    dateChange(val) {
      if (val) {
        this.startTime = this.filterTime(this.submitDate[0])
        this.endTime = this.filterTime(this.submitDate[1])
      } else {
        this.startTime = ''
        this.endTime = ''
      }
      this.pageNum = 1
      this.isChangePageNum = false
      this.$nextTick(() => {
        this.getData()
      })
    },
    filterTime(time) {
      var date = new Date(time)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      var h = date.getHours()
      h = h < 10 ? '0' + h : h
      var minute = date.getMinutes()
      minute = minute < 10 ? '0' + minute : minute
      var s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + s
    },
    tableCellStyle(row) {
      if (this.row === row.row) {
        return 'background-color:#f5f6fa;'
      } else {
        return 'background-color:#fff;'
      }
    },
    // 筛选改变
    filterChange(val) {
      if (val.channelType) {
        this.channelType = val.channelType.join(',')
      }
      this.pageNum = 1
      this.isChangePageNum = false
      this.$nextTick(() => {
        this.getData()
      })
    },
    // 分页
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNum = 1
      this.isChangePageNum = false
      this.getData()
    },
    handleCurrentChange(val) {
      // this.pageNum = val
      this.isChangePageNum = true
      // this.getData()
    }
  }
}
</script>

<style lang="less" scoped>
.recharge-record{
.detail{
    min-width: 690px;
    .detail-header{
      background: #F5F7FA;
      height: 62px;
      line-height: 62px;
      margin-bottom: 10px;
      ::v-deep .el-date-editor.el-input, .el-date-editor.el-input__wrapper{
        width: 240px;
      }
      ::v-deep .el-date-editor--datetimerange{
        --el-date-editor-width: 240px;
      }
      ::v-deep .el-date-editor--datetimerange.el-input, .el-date-editor--datetimerange.el-input__inner{
        width: 240px;
      }
      ::v-deep .el-date-editor{
        margin-left: 12px;
        height: 34px;
      }
      ::v-deep input {
        background-image: none;
      }
      ::v-deep .el-input{
        margin-right: 30px;
      }
      ::v-deep .el-input__wrapper{
        box-shadow: 0 0 0 1px #dcdcdc inset;
      }
      ::v-deep .el-input__wrapper.is-focus{
        box-shadow: 0 0 0 1px #dcdcdc inset;
      }
    }
    ::v-deep .el-input {
      --el-input-focus-border: #dcdfe6;
    }
    ::v-deep .el-table{
    .el-icon{
      color: #313a46;
      font-size: 12px;
    }
  }
  ::v-deep .el-icon{
          color: #dcdfe6;
          font-size: 16px;
        }
    .search {
      width: 200px;
      margin-left: 10px;
      ::v-deep .el-input__inner {
        border-radius: 2px;
        height: 32px;
        line-height: 32px;
        // width: 20%;
      }
    }
  }
}
</style>
