<template>
  <div class="account-view">
    <div class="account">
      <div class="view">
        <div class="title">
          <div style="display:flex;">
            <div style="padding-right:20px">账户总览</div>
            <!-- <div class="invoice" @click="openInvoiceControl">
              <svg-icon style="width:22px;height:22px;margin-top:1px;" icon-class="invoice" />
            </div> -->
          </div>
          <div class="month" @click="openMonthlyReport">
            月报 >
          </div>
        </div>
        <div class="view-content">
          <div class="box">
            <div>账户余额</div>
            <div class="money">￥{{ accountData.balance }}</div>
          </div>
          <div class="box">
            <div>消费累计</div>
            <div class="money">￥{{ accountData.comeoutMoney }}</div>
          </div>
          <div class="box">
            <div>充值累计</div>
            <div class="money">￥{{ accountData.comeinMoney }}</div>
          </div>
          <div class="box">
            <div>最高单日消费</div>
            <div class="money">￥{{ accountData.topConsume }}</div>
          </div>

          <div class="box">
            <div>本月消费情况</div>
            <div class="money">￥{{ accountData.accountMonthComeOut }}</div>
          </div>
          <div class="box">
            <div>本月平均每日消费</div>
            <div class="money">￥{{ accountData.averageConsume }}</div>
          </div>
        </div>
      </div>
      <div class="trend">
        <div class="title">消费趋势<span style="font-weight:400;">（近7日）</span></div>
        <div id="trend" class="trend-echart" style="width:100%; height:270px" />
      </div>
    </div>
    <div class="detail">
      <div class="detail-header">
        <el-input
          v-model="search"
          class="search"
          placeholder="搜索"
          prefix-icon="Search"
          clearable
          @input="searchHandle(search)"
        />
        <span style="submit-time">提交时间</span>
        <el-date-picker
          v-model="submitDate"
          type="datetimerange"
          start-placeholder="开始时间"
          end-placeholder="截止时间"
          @change="dateChange"
        />
      </div>
      <div>
        <el-table
          ref="consumeNote"
          :data="tableData"
          :row-style="tableCellStyle"
          style="width: 100%"
          @filter-change="filterChange"
        >
          <el-table-column
            prop="accountLogId"
            label="交易流水号"
          >
            <template #default="scope">
              <div style="color:#165dff;cursor:pointer;" @click="queryDetailByLogId(scope.row)">{{ scope.row.accountLogId }}</div>
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="consumerType"
            label="消费类型"
            column-key="consumerType"
            :filters="[
              { text: 'HPC-作业', value: 1 },
              { text: '磁盘配额', value: 2 },
              { text: 'AI', value: 3 }
            ]"
            :filtered-value="[1, 2, 3]"
          >
            <template #default="scope">
              <div>{{ scope.row.consumerType === 1 ? 'HPC-作业' : (scope.row.consumerType === 2 ? '磁盘配额' : 'AI') }}</div>
            </template>
          </el-table-column> -->
          <el-table-column
            prop="consumerType"
            label="消费类型"
            column-key="consumerType"
          >
            <template #default="scope">
              <div>{{ scope.row.consumerType === 1 ? 'HPC-作业' : (scope.row.consumerType === 2 ? '磁盘配额' : 'AI') }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="money"
            label="消费金额"
          >
            <template #default="scope">
              ￥{{ getMoney(scope.row.money) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="消费时间"
          />
          <el-table-column
            prop="consumptionState"
            column-key="consumptionState"
            label="消费状态"
            :filters="[
              { text: '支付失败', value: 0 },
              { text: '支付成功', value: 1 },
              { text: '待支付', value: 2 }
            ]"
            :filtered-value="[0, 1, 2]"
          >
            <template #default="scope">
              <div style="display: flex;">
                <div :class="scope.row.consumptionState === 0 ? 'anormaly-text' : (scope.row.consumptionState === 1 ? 'end-text' : 'normal-text')">{{ scope.row.consumptionState === 0 ? '支付失败' : (scope.row.consumptionState === 1 ? '支付成功' : '待支付') }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="remark"
            label="描述"
          >
            <template #default="scope">
              <div v-if="scope.row.remark">{{ scope.row.remark }}</div>
              <div v-else>无</div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <div class="total">合计：{{ totalPage }}</div>
          <el-pagination
            v-model:currentPage="pageNum"
            :page-size="pageSize"
            :page-count="pagenumber"
            :page-sizes="pageSizes"
            layout="sizes"
            :total="totalPage"
            @size-change="handleSizeChange"
          />
          <div class="jumper">
            <div>{{ pageNum }}/{{ pagenumber }}</div>
            <div class="jumper-line" />
            <el-pagination
              v-model:currentPage="pageNum"
              :page-size="pageSize"
              :page-count="pagenumber"
              :page-sizes="pageSizes"
              background
              layout="jumper"
              :total="totalPage"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
          <el-pagination
            v-model:currentPage="pageNum"
            :page-size="pageSize"
            :page-count="pagenumber"
            :page-sizes="pageSizes"
            background
            layout="prev, next"
            :total="totalPage"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <div class="drawer-box month-drawer-box">
      <el-drawer
        v-model="drawer"
        title="月报"
        show-close
        :direction="direction"
        :before-close="handleClose"
      >
        <div class="recharge-box">
          <div class="total-header">
            <div style="width:33.3%;">
              <span>选择月份</span>
              <el-date-picker
                v-model="queryMonth"
                type="month"
                placeholder="请选择月份"
                @change="monthChange"
              />
            </div>
            <div class="total">
              充值总额
              <span>￥{{ totalRecharge }}</span>
            </div>
            <div class="total">
              消费总额
              <span>￥{{ totalConsumption }}</span>
            </div>
          </div>
          <div class="recharge-content">
            <div>充值明细</div>
            <div v-loading="drawerLoading">
              <el-table
                ref="monthRechargeTable"
                :data="tableDataRecharge"
                :row-style="tableCellStyleRecharge"
                style="width: 100%"
                @filter-change="filterChangeRecharge"
              >
                <el-table-column
                  prop="accountLogId"
                  label="交易流水号"
                />
                <el-table-column
                  prop="comeInReal"
                  label="支付金额"
                >
                  <template #default="scope">
                    ￥{{ getMoney(scope.row.comeInReal) }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="channelType"
                  label="充值渠道"
                  column-key="channelType"
                  :filters="[
                    { text: '支付宝', value: 'alipay' },
                    { text: '微信', value: 'wxpay' },
                    { text: '系统充值', value: 'system' }
                  ]"
                  :filtered-value="['alipay', 'wxpay', 'system']"
                >
                  <template #default="scope">
                    <div>{{ scope.row.channelType === 'alipay' ? '支付宝' : (scope.row.channelType === 'wxpay' ? '微信' : '系统充值') }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="comeIn"
                  label="到账金额"
                >
                  <template #default="scope">
                    ￥{{ getMoney(scope.row.comeIn) }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="comeFree"
                  label="赠送金额"
                >
                  <template #default="scope">
                    ￥{{ getMoney(scope.row.comeFree) }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="createTime"
                  :formatter="formatTime"
                  label="交易时间"
                />
                <el-table-column
                  prop="consumptionState"
                  column-key="consumptionState"
                  label="状态"
                  :filters="[
                    { text: '支付失败', value: 0 },
                    { text: '支付成功', value: 1 },
                    { text: '待支付', value: 2 }
                  ]"
                  :filtered-value="[0, 1, 2]"
                >
                  <template #default="scope">
                    <div style="display: flex;">
                      <div :class="scope.row.consumptionState === 0 ? 'anormaly-text' : (scope.row.consumptionState === 1 ? 'end-text' : 'normal-text')">{{ scope.row.consumptionState === 0 ? '支付失败' : (scope.row.consumptionState === 1 ? '支付成功' : '待支付') }}</div>
                    </div>
                  </template>
                </el-table-column>
                <!-- <el-table-column
                  prop="remark"
                  label="描述"
                >
                  <template #default="scope">
                    <div v-if="scope.row.remark">{{ scope.row.remark }}</div>
                    <div v-else>无</div>
                  </template>
                </el-table-column> -->
              </el-table>
              <div class="pagination">
                <div class="total">合计：{{ totalPageRecharge }}</div>
                <el-pagination
                  v-model:currentPage="pageNumRecharge"
                  :page-size="pageSizeRecharge"
                  :page-count="pagenumberRecharge"
                  :page-sizes="pageSizesRecharge"
                  layout="sizes"
                  :total="totalPageRecharge"
                  @size-change="handleSizeChangeRecharge"
                />
                <div class="jumper">
                  <div>{{ pageNumRecharge }}/{{ pagenumberRecharge }}</div>
                  <div class="jumper-line" />
                  <el-pagination
                    v-model:currentPage="pageNumRecharge"
                    :page-size="pageSizeRecharge"
                    :page-count="pagenumberRecharge"
                    :page-sizes="pageSizesRecharge"
                    background
                    layout="jumper"
                    :total="totalPageRecharge"
                    @size-change="handleSizeChangeRecharge"
                    @current-change="handleCurrentChangeRecharge"
                  />
                </div>
                <el-pagination
                  v-model:currentPage="pageNumRecharge"
                  :page-size="pageSizeRecharge"
                  :page-count="pagenumberRecharge"
                  :page-sizes="pageSizesRecharge"
                  background
                  layout="prev, next"
                  :total="totalPageRecharge"
                  @size-change="handleSizeChangeRecharge"
                  @current-change="handleCurrentChangeRecharge"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="consume-box">
          <consume-type-table :month="month" @getConsumerTotal="getConsumerTotal" />
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import { getAccountMessage, getConsumeNote, getRechargeNoteByMonth } from '@/api/ehpc'
import { markRaw } from 'vue'
import consumeTypeTable from '@/components/ConsumeTypeTable.vue'
export default {
  components: { consumeTypeTable },
  data() {
    return {
      startTime: null,
      endTime: null,
      consumptionState: [],
      consumerType: [],
      // 搜索
      submitDate: '',
      tableData: [],
      loading: false,
      pageSize: 10,
      row: '',
      pageNum: 1,
      pagenumber: 0,
      totalPage: 0,
      pageSizes: [5, 10, 20, 30],
      search: '',
      money: 0,
      accountData: {
        balance: 0,
        comeoutMoney: 0,
        comeinMoney: 0,
        accountMonthComeOut: 0,
        topConsume: 0,
        averageConsume: 0
      },
      myChart: null,
      xData: [],
      yData: [],
      timer: null,
      isChangePageNum: false,
      // 月报参数
      drawer: false,
      drawerLoading: false,
      direction: 'rtl',
      queryMonth: null,
      month: '',
      totalConsumption: 0,
      totalRecharge: 0,
      channelType: [],
      monthConsumptionState: [],
      tableDataRecharge: [],
      pageSizeRecharge: 10,
      rowRecharge: '',
      isChangePageNumRecharge: false,
      pageNumRecharge: 1,
      pagenumberRecharge: 0,
      totalPageRecharge: 0,
      pageSizesRecharge: [5, 10, 20, 30]
    }
  },
  watch: {
    pageNum: {
      handler(val, oldVal) {
        if (this.isChangePageNum) {
          this.pageNum = val
          this.getConsumeNotes()
        }
      },
      deep: true
    },
    pageNumRecharge: {
      handler(val, oldVal) {
        if (this.isChangePageNumRecharge) {
          this.pageNumRecharge = val
          this.getRechargeNoteByMonth()
        }
      },
      deep: true
    }
  },
  mounted() {
    this.getAccountMessage()
    const that = this
    const elementResizeDetectorMaker = require('element-resize-detector')// 导入
    // 创建实例
    const erd = elementResizeDetectorMaker()
    // 创建实例带参
    const erdUltraFast = elementResizeDetectorMaker({
      strategy: 'scroll', // <- For ultra performance.
      callOnAdd: true,
      debug: true
    })
    const echarts = require('echarts')
    const trend = echarts.init(document.getElementById('trend'))
    // 监听id为test的元素 大小变化
    const i = document.getElementsByClassName('account-right').length - 1
    erd.listenTo(document.getElementsByClassName('account-right')[i], function(element) {
      try {
        setTimeout(() => {
          trend.resize()
        }, 100)
      } catch (error) {
        console.log(error)
      }
    })
  },
  beforeUnmount() {
    if (this.myChart) {
      this.myChart.dispose()
    }
  },
  methods: {
    getMoney(money) {
      return (money / 1000).toFixed(3).replace(/\.?0+$/, '')
    },
    // 时间格式化
    formatTime(row, column) {
      const data = row[column.property]
      const dtime = new Date(data)
      const year = dtime.getFullYear()
      let month = dtime.getMonth() + 1
      if (month < 10) {
        month = '0' + month
      }
      let day = dtime.getDate()
      if (day < 10) {
        day = '0' + day
      }
      let hour = dtime.getHours()
      if (hour < 10) {
        hour = '0' + hour
      }
      let minute = dtime.getMinutes()
      if (minute < 10) {
        minute = '0' + minute
      }
      let second = dtime.getSeconds()
      if (second < 10) {
        second = '0' + second
      }
      return (
        year +
        '-' +
        month +
        '-' +
        day +
        ' ' +
        hour +
        ':' +
        minute +
        ':' +
        second
      )
    },
    // 标题标签切换
    tagHandle(item, i) {
      this.accountActive = i
    },
    // 打开发票管理
    openInvoiceControl() {
      this.$store.dispatch('core/openApp', 8889162036467448)
    },
    // 表格过滤 消费类型
    filterHandler(value, row, column) {
      const property = column.property
      return row[property] === value
    },
    // 表格过滤 状态
    filterStateHandler(value, row, column) {
      const property = column.property
      return row[property] === value
    },
    getAccountMessage() {
      // this.loading = true
      this.$emit('viewLoadingChange', true)
      getAccountMessage().then(res => {
        if (res.meta.status === 200) {
          this.accountData = res.data
          this.accountData.balance = (this.accountData.balance / 1000).toFixed(3).replace(/\.?0+$/, '')
          this.accountData.comeoutMoney = (this.accountData.comeoutMoney / 1000).toFixed(3).replace(/\.?0+$/, '')
          this.accountData.comeinMoney = (this.accountData.comeinMoney / 1000).toFixed(3).replace(/\.?0+$/, '')
          this.accountData.accountMonthComeOut = (this.accountData.accountMonthComeOut / 1000).toFixed(3).replace(/\.?0+$/, '')
          this.accountData.topConsume = (this.accountData.topConsume / 1000).toFixed(3).replace(/\.?0+$/, '')
          this.accountData.averageConsume = (this.accountData.averageConsume / 1000).toFixed(3).replace(/\.?0+$/, '')
          res.data.sevenDailyConsume.forEach(e => {
            this.xData.unshift(e.day)
            this.yData.unshift((e.consume / 1000).toFixed(3).replace(/\.?0+$/, ''))
          })
          const that = this
          setTimeout(
            function() {
              that.initEchart()
              that.getConsumeNotes()
            }, 1500)
        }
      })
    },
    // 消费记录
    getConsumeNotes() {
      // this.loading = true
      this.$emit('viewLoadingChange', true)
      // 消费记录
      const data = {
        pagenum: this.pageNum,
        pagesize: this.pageSize,
        // sortBy: sortBy,
        // orderBy: orderBy,
        keyWord: this.search,
        startTime: this.startTime,
        endTime: this.endTime,
        consumerType: this.consumerType,
        consumptionState: this.consumptionState
      }
      getConsumeNote(data).then(res => {
        if (res.meta.status === 200) {
          this.tableData = res.data.content
          this.totalPage = res.data.totalelements
          this.pagenumber = res.data.totalpages
          // this.loading = false
          this.$emit('viewLoadingChange', false)
        } else {
          this.$emit('viewLoadingChange', false)
        }
      }).catch(() => {
        // this.loading = false
        this.$emit('viewLoadingChange', false)
      })
    },
    // 根据交易流水号查询详单
    queryDetailByLogId(row) {
      this.$emit('queryDetailByLogId', row.accountLogId, row.consumerType)
    },
    initEchart() {
      const echarts = require('echarts')
      this.myChart = markRaw(echarts.init(document.getElementById('trend')))
      this.myChart.setOption({
        title: {
          // text: '消费趋势'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        grid: {
          left: '5%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: this.xData,
            axisLabel: {
              interval: 0,
              rotate: 60
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            }
          }
        ],
        series: [
          {
            name: '总量',
            type: 'line',
            stack: '总量',
            itemStyle: {
              normal: {
                areaStyle: {
                  type: 'default',
                  // 渐变色实现===
                  color: echarts.graphic.LinearGradient(0, 0, 0, 1,
                    // 三种由深及浅的颜色
                    [{
                      offset: 0,
                      color: 'rgba(11,123,255,0.2)'
                    }, {
                      offset: 1,
                      color: 'rgba(17,128,255,0)'
                    }])
                },
                lineStyle: { // 线的颜色
                  color: '#0B7BFF'
                },
                // 以及在折线图每个日期点顶端显示数字
                label: {
                  show: true,
                  position: 'top',
                  textStyle: {
                    color: 'white'
                  }
                }
              }
            },
            smooth: true,
            showSymbol: false,
            areaStyle: {},
            data: this.yData
          }
        ]
      })
    },
    // 搜索
    searchHandle() {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.pageNum = 1
        this.isChangePageNum = false
        this.$nextTick(() => {
          this.getConsumeNotes()
        })
      }, 500)
    },
    // 提交时间更改
    dateChange(val) {
      if (val) {
        this.startTime = this.filterTime(this.submitDate[0])
        this.endTime = this.filterTime(this.submitDate[1])
      } else {
        this.startTime = ''
        this.endTime = ''
      }
      this.pageNum = 1
      this.isChangePageNum = false
      this.$nextTick(() => {
        this.getConsumeNotes()
      })
    },
    filterTime(time) {
      var date = new Date(time)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      var h = date.getHours()
      h = h < 10 ? '0' + h : h
      var minute = date.getMinutes()
      minute = minute < 10 ? '0' + minute : minute
      var s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + s
    },
    filterMonthTime(time) {
      var date
      if (time) {
        date = new Date(time)
      } else {
        date = new Date()
      }
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      var h = date.getHours()
      h = h < 10 ? '0' + h : h
      var minute = date.getMinutes()
      minute = minute < 10 ? '0' + minute : minute
      var s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + m
    },
    tableCellStyle(row) {
      if (this.row === row.row) {
        return 'background-color:#f5f6fa;'
      } else {
        return 'background-color:#fff;'
      }
    },
    // 筛选改变
    filterChange(val) {
      if (val.consumptionState) {
        this.consumptionState = val.consumptionState.join(',')
      }
      if (val.consumerType) {
        this.consumerType = val.consumerType.join(',')
      }
      this.pageNum = 1
      this.isChangePageNum = false
      this.$nextTick(() => {
        this.getConsumeNotes()
      })
    },
    // 分页
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNum = 1
      this.isChangePageNum = false
      this.getConsumeNotes()
    },
    handleCurrentChange(val) {
      // this.pageNum = val
      this.isChangePageNum = true
      // this.getConsumeNotes('stateTime', 'DESC')
    },
    // 月报
    // 打开月报
    openMonthlyReport() {
      sessionStorage.userAccountManual = 'month'
      this.$store.dispatch('core/userAccountManual', -1)
      // const currentDate = new Date()
      // this.queryMonth = currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1)
      this.queryMonth = this.filterMonthTime()
      this.month = this.queryMonth
      this.drawer = true
      this.$nextTick(() => {
        this.getRechargeNoteByMonth()
      })
    },
    // 消费总额
    getConsumerTotal(val) {
      if (val) {
        this.totalConsumption = (val / 1000).toFixed(3).replace(/\.?0+$/, '')
      } else {
        this.totalConsumption = 0
      }
    },
    getRechargeNoteByMonth() {
      this.drawerLoading = true
      // 作业状态
      const data = {
        pagenum: this.pageNumRecharge,
        pagesize: this.pageSizeRecharge,
        // sortBy: this.sortBy,
        // orderBy: this.orderBy,
        consumptionState: this.monthConsumptionState,
        channelType: this.channelType,
        month: this.queryMonth
      }
      getRechargeNoteByMonth(data).then(res => {
        if (res.meta.status === 200) {
          if (res.data.comeInMonth) {
            this.totalRecharge = (res.data.comeInMonth / 1000).toFixed(3).replace(/\.?0+$/, '')
          } else {
            this.totalRecharge = 0
          }
          this.tableDataRecharge = res.data.rechargeMonth.content
          this.totalPageRecharge = res.data.rechargeMonth.totalelements
          this.pagenumberRecharge = res.data.rechargeMonth.totalpages
          this.drawerLoading = false
        } else {
          this.tableDataRecharge = []
          this.drawerLoading = false
        }
      }).catch(() => {
        this.tableDataRecharge = []
        this.drawerLoading = false
      })
    },
    // 获取该月充值数据
    // 月更改
    monthChange(val) {
      if (val) {
        this.queryMonth = this.filterMonthTime(val)
        this.month = this.queryMonth
      } else {
        this.queryMonth = ''
        this.month = this.queryMonth
      }
      this.queryMonth = this.filterMonthTime(val)
      this.month = this.queryMonth
      this.pageNumRecharge = 1
      this.isChangePageNumRecharge = false
      this.$nextTick(() => {
        this.getRechargeNoteByMonth()
      })
    },
    tableCellStyleRecharge(row) {
      if (this.rowRecharge === row.row) {
        return 'background-color:#f5f6fa;'
      } else {
        return 'background-color:#fff;'
      }
    },
    // 筛选改变
    filterChangeRecharge(val) {
      if (val.channelType) {
        this.channelType = val.channelType.join(',')
      }
      if (val.consumptionState) {
        this.monthConsumptionState = val.consumptionState.join(',')
      }
      this.pageNumRecharge = 1
      this.isChangePageNumRecharge = false
      this.$nextTick(() => {
        this.getRechargeNoteByMonth()
      })
    },
    // 分页
    handleSizeChangeRecharge(val) {
      this.pageSizeRecharge = val
      this.pageNumRecharge = 1
      this.isChangePageNumRecharge = false
      this.getRechargeNoteByMonth()
    },
    handleCurrentChangeRecharge(val) {
      // this.pageNumRecharge = val
      this.isChangePageNumRecharge = true
      // this.getRechargeNoteByMonth()
    }
  }
}
</script>

<style lang="less" scoped>
.account-view{
  height: 100%;
  width: 100%;
  .title{
    color: #303133;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .month{
      padding-right: 60px;
      font-size: 14px;
      color: #165DFF;
      font-weight: 400;
    }
  }
  .account{
    display: flex;
    min-width: 690px;
    .view{
      width: 50%;
      .view-content{
        display: flex;
        flex-wrap: wrap;
        .box {
          padding-top: 28px;
          font-size: 14px;
          color: #000;
          width: 150px;
          word-wrap: break-word;
          .money{
            color: #165DFF;
            font-size: 18px;
            padding-top: 6px;
          }
        }
      }
    }
    .trend{
      width: 50%;
      .title{
        display: inline;
      }
      .trend-echart{
        width: 100%;
        // height: 100%;
        height: 270px;
      }
    }
  }
  .detail{
    min-width: 690px;
    padding-top: 20px;
    padding-bottom: 20px;
    .detail-header{
      background: #F5F7FA;
      height: 62px;
      line-height: 62px;
      margin-bottom: 10px;
      ::v-deep .el-date-editor.el-input, .el-date-editor.el-input__wrapper{
        width: 240px;
      }
      ::v-deep .el-date-editor--datetimerange{
        --el-date-editor-width: 240px;
      }
      ::v-deep .el-date-editor{
        margin-left: 12px;
        height: 34px;
      }
      ::v-deep input {
        background-image: none;
      }
      ::v-deep .el-input{
        margin-right: 30px;
      }
      ::v-deep .el-input__wrapper{
        box-shadow: 0 0 0 1px #dcdcdc inset;
      }
      ::v-deep .el-input__wrapper.is-focus{
        box-shadow: 0 0 0 1px #dcdcdc inset;
      }
    }
    ::v-deep .el-input {
      --el-input-focus-border: #dcdfe6;
    }
    ::v-deep .el-table{
    .el-icon{
      color: #313a46;
      font-size: 12px;
    }
  }
    ::v-deep .el-icon{
          color: #dcdfe6;
          font-size: 16px;
        }
    .search {
      width: 200px;
      margin-left: 10px;
      ::v-deep .el-input__inner {
        border-radius: 2px;
        height: 32px;
        line-height: 32px;
        // width: 20%;
      }
    }
    .title{
      padding-bottom: 20px;
    }
  }
}
</style>
<style>
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 12px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3); */
  border-radius: 6px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.5); */
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #d8d8d8;
}
</style>
