<template>
  <div class="order-detail">
    <div class="tab-list">
      <div
        v-for="(item, i) in tabList"
        :key="i"
        class="tab-item"
        :class="{ active: i === activeColor }"
        @click="changeClick(item, i)"
      >
        <svg-icon :icon-class="[i === activeColor ? item.activeIcon : item.icon]" />
        <div>{{ item.name }}</div>
      </div>
    </div>
    <div class="detail">
      <div class="detail-header">
        <el-input
          v-model="search"
          class="search"
          placeholder="搜索"
          prefix-icon="Search"
          clearable
          @input="searchHandle(search)"
        />
        <span v-if="activeColor===0" style="submit-time">最后消费时间</span>
        <span v-if="activeColor===1" style="submit-time">消费时间</span>
        <span v-if="activeColor===2" style="submit-time">购买时间</span>
        <el-date-picker
          v-model="submitDate"
          type="datetimerange"
          start-placeholder="开始时间"
          end-placeholder="截止时间"
          @change="dateChange"
        />
      </div>
      <div>
        <!-- AI -->
        <el-table
          v-if="activeColor===1"
          ref="aiTable"
          :data="tableData"
          :row-style="tableCellStyle"
          style="width: 100%"
          @filter-change="filterChange"
        >
          <el-table-column
            prop="accountLogId"
            label="交易流水号"
          />
          <el-table-column
            prop="podName"
            label="pod名称"
          />
          <el-table-column
            prop="money"
            label="消费金额"
          >
            <template #default="scope">
              ￥{{ getMoney(scope.row.money) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="consumptionState"
            column-key="consumptionState"
            label="消费状态"
            :filters="[
              { text: '支付失败', value: 0 },
              { text: '支付成功', value: 1 },
              { text: '待支付', value: 2 }
            ]"
            :filtered-value="[0, 1, 2]"
          >
            <template #default="scope">
              <div style="display: flex;">
                <div :class="scope.row.consumptionState === 0 ? 'anormaly-text' : (scope.row.consumptionState === 1 ? 'end-text' : 'normal-text')">{{ scope.row.consumptionState === 0 ? '支付失败' : (scope.row.consumptionState === 1 ? '支付成功' : '待支付') }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="消费时间"
          />
          <el-table-column
            prop="realConsumeProjectName"
            label="项目组"
          />
          <el-table-column
            prop="accountName"
            label="消费人"
          />
          <el-table-column
            prop="realConsumeAccountName"
            label="扣费账户"
          />
        </el-table>
        <!-- 作业消费 -->
        <el-table
          v-else-if="activeColor===0"
          ref="jobTable"
          :data="tableData"
          :row-style="tableCellStyle"
          style="width: 100%"
          @filter-change="filterChange"
        >
          <el-table-column
            prop="accountLogId"
            label="交易流水号"
          />
          <el-table-column
            prop="jobId"
            label="作业ID"
          />
          <el-table-column
            prop="jobName"
            label="作业名称"
          />
          <el-table-column
            prop="cpusReq"
            label="作业规模"
          />
          <el-table-column
            prop="jobState"
            label="作业状态"
            column-key="jobState"
            :filters="[
              { text: '运行中', value: 0 },
              { text: '已完成', value: 2 },
              { text: '异常', value: 3 }
            ]"
            :filtered-value="[0, 2, 3]"
          >
            <template #default="scope">
              <div
                v-if="scope.row.jobState === 'JOB_RUNNING' || scope.row.jobState === 0"
                class="state_circles"
                style="color: #165DFF"
              >
                <div>运行中</div>
              </div>
              <div
                v-if="scope.row.jobState === 'JOB_COMPLETE' || scope.row.jobState === 2"
                class="state_circles"
                style="color: #00A870"
              >
                <div>已完成</div>
              </div>
              <div
                v-if="scope.row.jobState === 1"
                class="state_circles"
                style="color: #FD9851"
              >
                <div>排队中</div>
              </div>
              <div
                v-if="scope.row.jobState === 3"
                class="state_circles"
                style="color: #F02E3E"
              >
                <div>异常</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="money"
            label="消费金额"
          >
            <template #default="scope">
              ￥{{ getMoney(scope.row.money) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="consumptionState"
            column-key="consumptionState"
            label="状态"
            :filters="[
              { text: '支付失败', value: 0 },
              { text: '支付成功', value: 1 },
              { text: '待支付', value: 2 }
            ]"
            :filtered-value="[0, 1, 2]"
          >
            <template #default="scope">
              <div style="display: flex;">
                <div :class="scope.row.consumptionState === 0 ? 'anormaly-text' : (scope.row.consumptionState === 1 ? 'end-text' : 'normal-text')">{{ scope.row.consumptionState === 0 ? '支付失败' : (scope.row.consumptionState === 1 ? '支付成功' : '待支付') }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="消费开始时间"
          />
          <el-table-column
            prop="stateTime"
            label="最后消费时间"
          >
            <template #default="scope">
              {{ filterTime(scope.row.stateTime) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="remark"
            label="消费描述"
          />
        </el-table>
        <!-- 磁盘配额 -->
        <el-table
          v-else
          ref="quotaTable"
          :data="tableData"
          :row-style="tableCellStyle"
          style="width: 100%"
          @filter-change="filterChange"
        >
          <el-table-column
            prop="accountLogId"
            label="交易流水号"
          />
          <el-table-column
            prop="money"
            label="交易金额"
          >
            <template #default="scope">
              ￥{{ getMoney(scope.row.money) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="consumptionState"
            column-key="consumptionState"
            label="交易状态"
            :filters="[
              { text: '支付失败', value: 0 },
              { text: '支付成功', value: 1 },
              { text: '待支付', value: 2 }
            ]"
            :filtered-value="[0, 1, 2]"
          >
            <template #default="scope">
              <div style="display: flex;">
                <div :class="scope.row.consumptionState === 0 ? 'anormaly-text' : (scope.row.consumptionState === 1 ? 'end-text' : 'normal-text')">{{ scope.row.consumptionState === 0 ? '支付失败' : (scope.row.consumptionState === 1 ? '支付成功' : '待支付') }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="quotaSize"
            label="扩容"
          />
          <el-table-column
            prop="endTime"
            label="有效期"
          >
            <template #default="scope">
              <div v-if="scope.row.endTime===null">
                永久有效
              </div>
              <div v-else>
                {{ scope.row.endTime }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="quotaState"
            label="状态"
            column-key="quotaState"
            :filters="[
              { text: '有效', value: 1 },
              { text: '已失效', value: 2 }
            ]"
            :filtered-value="[1, 2]"
          >
            <template #default="scope">
              <div style="display: flex;">
                <div :class="scope.row.quotaState === 2 ? 'anormaly-text' : 'normal-text'">{{ scope.row.quotaState === 2 ? '已失效' : '有效' }}</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <div class="total">合计：{{ totalPage }}</div>
          <el-pagination
            v-model:currentPage="pageNum"
            :page-size="pageSize"
            :page-count="pagenumber"
            :page-sizes="pageSizes"
            layout="sizes"
            :total="totalPage"
            @size-change="handleSizeChange"
          />
          <div class="jumper">
            <div>{{ pageNum }}/{{ pagenumber }}</div>
            <div class="jumper-line" />
            <el-pagination
              v-model:currentPage="pageNum"
              :page-size="pageSize"
              :page-count="pagenumber"
              :page-sizes="pageSizes"
              background
              layout="jumper"
              :total="totalPage"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
          <el-pagination
            v-model:currentPage="pageNum"
            :page-size="pageSize"
            :page-count="pagenumber"
            :page-sizes="pageSizes"
            background
            layout="prev, next"
            :total="totalPage"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getConsumptionDetail } from '@/api/ehpc'
export default {
  props: ['id', 'type'],
  data() {
    return {
      activeColor: 0,
      tabList: [{
        name: '作业消费',
        activeIcon: 'job-on',
        icon: 'job'
      // },
      // {
      //   name: 'AI消费',
      //   activeIcon: 'ai-on',
      //   icon: 'ai'
      // },
      // {
      //   name: '磁盘配额订单',
      //   activeIcon: 'quota-on',
      //   icon: 'quota'
      }],
      startTime: null,
      endTime: null,
      consumptionState: [],
      jobState: [],
      quotaState: [],
      // 搜索
      submitDate: '',
      tableData: [],
      loading: false,
      pageSize: 10,
      row: '',
      pageNum: 1,
      pagenumber: 0,
      totalPage: 0,
      pageSizes: [5, 10, 20, 30],
      search: '',
      sortBy: '',
      orderBy: '',
      isChangePageNum: false
    }
  },
  watch: {
    pageNum: {
      handler(val, oldVal) {
        if (this.isChangePageNum) {
          this.pageNum = val
          this.getData()
        }
      },
      deep: true
    }
  },
  mounted() {
    if (sessionStorage.detailKeyword != 'null') {
      this.search = sessionStorage.detailKeyword
      const val = sessionStorage.detailConsumerType
      if (val == 1) {
        this.activeColor = 0
      } else if (val == 3) {
        this.activeColor = 1
      } else {
        this.activeColor = 2
      }
    }
    this.getData()
  },
  methods: {
    getMoney(money) {
      return (money / 1000).toFixed(3).replace(/\.?0+$/, '')
    },
    changeClick(item, i) {
      if (i !== this.activeColor) {
        this.activeColor = i
        this.tableData = []
        this.jobState = []
        this.quotaState = []
        this.consumptionState = []
        this.pageNum = 1
        this.isChangePageNum = false
        this.search = ''
        this.submitDate = ''
        this.$nextTick(() => {
          this.getData()
        })
      }
    },
    getData() {
      this.$emit('viewLoadingChange', true)
      // 作业状态
      const data = {
        pagenum: this.pageNum,
        pagesize: this.pageSize,
        // sortBy: this.sortBy,
        // orderBy: this.orderBy,
        keyWord: this.search,
        startTime: this.startTime,
        endTime: this.endTime,
        jobState: this.jobState,
        consumptionState: this.consumptionState,
        consumerType: this.activeColor === 0 ? 1 : this.activeColor === 1 ? 3 : 2
      }
      getConsumptionDetail(data).then(res => {
        if (res.meta.status === 200) {
          this.$emit('viewLoadingChange', false)
          this.tableData = res.data.content
          this.totalPage = res.data.totalelements
          this.pagenumber = res.data.totalpages
        } else {
          this.$emit('viewLoadingChange', false)
        }
      }).catch(() => {
        this.$emit('viewLoadingChange', false)
      })
    },
    // 搜索
    searchHandle() {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.pageNum = 1
        this.isChangePageNum = false
        this.$nextTick(() => {
          this.getData()
        })
      }, 500)
    },
    // 提交时间更改
    dateChange(val) {
      if (val) {
        this.startTime = this.filterTime(this.submitDate[0])
        this.endTime = this.filterTime(this.submitDate[1])
      } else {
        this.startTime = ''
        this.endTime = ''
      }
      this.pageNum = 1
      this.isChangePageNum = false
      this.$nextTick(() => {
        this.getData()
      })
    },
    filterTime(time) {
      var date = new Date(time)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      var h = date.getHours()
      h = h < 10 ? '0' + h : h
      var minute = date.getMinutes()
      minute = minute < 10 ? '0' + minute : minute
      var s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + s
    },
    tableCellStyle(row) {
      if (this.row === row.row) {
        return 'background-color:#f5f6fa;'
      } else {
        return 'background-color:#fff;'
      }
    },
    // 筛选改变
    filterChange(val) {
      if (val.consumptionState) {
        this.consumptionState = val.consumptionState.join(',')
      }
      if (val.jobState) {
        this.jobState = val.jobState.join(',')
      }
      if (val.quotaState) {
        this.quotaState = val.quotaState.join(',')
      }
      this.pageNum = 1
      this.isChangePageNum = false
      this.$nextTick(() => {
        this.getData()
      })
    },
    // 分页
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNum = 1
      this.isChangePageNum = false
      this.getData()
    },
    handleCurrentChange(val) {
      // this.pageNum = val
      this.isChangePageNum = true
      // this.getData()
    }
  }
}
</script>

<style lang="less" scoped>
.order-detail{
  .tab-list{
    padding-left: 16px;
    padding-bottom: 16px;
    display: flex;
    .tab-item{
      width: 84px;
      color: #303133;
      font-size: 14px;
      text-align: center;
      margin-right: 32px;
      .svg-icon{
        width: 36px;
        height: 36px;
      }
    }
    .active{
      color: #165dff;
    }
  }
  .detail{
    min-width: 690px;
    padding-top: 20px;
    .detail-header{
      background: #F5F7FA;
      height: 62px;
      line-height: 62px;
      margin-bottom: 10px;
      ::v-deep .el-date-editor.el-input, .el-date-editor.el-input__wrapper{
        width: 240px;
      }
      ::v-deep .el-date-editor--datetimerange{
        --el-date-editor-width: 240px;
      }
      ::v-deep .el-date-editor--datetimerange.el-input, .el-date-editor--datetimerange.el-input__inner{
        width: 240px;
      }
      ::v-deep .el-date-editor{
        margin-left: 12px;
        height: 34px;
      }
      ::v-deep input {
        background-image: none;
      }
      ::v-deep .el-input{
        margin-right: 30px;
      }
      ::v-deep .el-input__wrapper{
        box-shadow: 0 0 0 1px #dcdcdc inset;
      }
      ::v-deep .el-input__wrapper.is-focus{
        box-shadow: 0 0 0 1px #dcdcdc inset;
      }
    }
    ::v-deep .el-input {
      --el-input-focus-border: #dcdfe6;
    }
    ::v-deep .el-table{
    .el-icon{
      color: #313a46;
      font-size: 12px;
    }
  }
  ::v-deep .el-icon{
          color: #dcdfe6;
          font-size: 16px;
        }
    .search {
      width: 200px;
      margin-left: 10px;
      ::v-deep .el-input__inner {
        border-radius: 2px;
        height: 32px;
        line-height: 32px;
        // width: 20%;
      }
    }
  }
}
</style>
